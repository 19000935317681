module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WD4277","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"gtm.start":1714055031275,"event":"gtm.js"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://docs.rackspace.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Office 365 at Rackspace Technology Support Portal","short_name":"Office 365 at Rackspace Technology Support Portal","start_url":"/","display":"standalone","icon":"src/images/rackspace-icon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e0ca60334750b0f894d1cd14fb60e0a9"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"wrapperStyle":{"marginBottom":20,"marginTop":20}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
