import React from 'react';
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage/session';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './reducer.js';

let composeEnhancer;
const isBrowser = typeof window !== `undefined`;

if (isBrowser) {
  const isReduxDevtoolsInstalled =
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === `function`;

  composeEnhancer = isReduxDevtoolsInstalled ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
} else {
  composeEnhancer = compose;
}

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['root'],
};

const statusPersistConfig = {
  key: 'status',
  storage,
  whitelist: ['logoutStatus'],
};

const configuredReducer = persistReducer(statusPersistConfig, rootReducer);

const persistedReducer = persistReducer(rootPersistConfig, configuredReducer);

const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(thunkMiddleware))
);

const persistor = persistStore(store);

export default ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
};
