/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/index.css';
import wrapWithProvider from './src/store';

export const wrapRootElement = wrapWithProvider;
