import produce from 'immer';

import {
  AUTHENTICATE_USER,
  LOAD_ASSIGNED_LICENSES,
  LOG_IN,
  LOG_OUT,
  READ_USER_PROFILE,
} from './actionTypes';

const initialState = {
  account: null,
  accountStatus: null,
  assignedLicenses: null,
  assignedLicensesStatus: null,
  loginStatus: null,
  logoutStatus: null,
  profile: null,
  profileStatus: null,
};

const rootReducer = produce((draftState = initialState, action) => {
  switch (action.type) {
    case LOAD_ASSIGNED_LICENSES: {
      draftState.assignedLicenses = action.assignedLicenses;
      draftState.assignedLicensesStatus = action.status;
      return draftState;
    }
    case READ_USER_PROFILE: {
      draftState.profile = action.profile;
      draftState.profileStatus = action.status;
      return draftState;
    }
    case AUTHENTICATE_USER: {
      draftState.account = action.account || draftState.account;
      draftState.accountStatus = action.status;
      draftState.loginStatus = action.loginStatus || draftState.loginStatus;
      return draftState;
    }
    case LOG_IN: {
      draftState.loginStatus = action.status;
      return draftState;
    }
    case LOG_OUT: {
      draftState.account = null;
      draftState.accountStatus = null;
      draftState.assignedLicenses = null;
      draftState.assignedLicensesStatus = null;
      draftState.loginStatus = null;
      draftState.logoutStatus = action.status;
      draftState.profile = null;
      draftState.profileStatus = null;
      return draftState;
    }
    default:
      return draftState;
  }
}, initialState);

export default rootReducer;
